import {
  ApplicationConfig,
  EnvironmentProviders,
  ErrorHandler,
  Provider,
  importProvidersFrom,
} from '@angular/core';

import { provideAnimations } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  CurrencyPipe,
  DATE_PIPE_DEFAULT_OPTIONS,
  DatePipe,
  DatePipeConfig,
  TitleCasePipe,
} from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ErrorReporterService,
  GlobalErrorHandlerService,
  LoaderService,
  RequestLoggerInterceptorService,
  SharedModule,
  UtilityPipesModule,
} from 'ets-fe-ng-sdk';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './@ngrx/index.reducer';
import { UtilityService } from './Services/utility.service';
import {
  DEFAULT_TIMEOUT,
  RequestTimeoutInterceptorService,
} from './Services/Interceptors/request-timeout.service';
import { AuthenticationInterceptorService } from './Services/Interceptors/authentication-interceptor.service';
import { LoggerInterceptorService } from './Services/Interceptors/logger-interceptor.service';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { IsNavMenuEnabledPipe } from './Shared/pipes/utility.pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgIdleModule } from '@ng-idle/core';
import { NotificationsModule } from './Shared/components/notifications/notifications.module';
import { QuillModule } from 'ngx-quill';
import { IdlerModule } from './Shared/components/idler/idler.module';
import { MatNativeDateModule } from '@angular/material/core';
import { Chart, registerables } from 'chart.js';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { effects } from './@ngrx/index.effect';
import { Routes, provideRouter } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Environment } from './Shared/models/environment.model';
import { coreToJupiterInterceptor } from 'projects/evolutics-client-ui/src/app/Services/core-to-jupiter.interceptor';
import { environment } from '../environments/environment';
import QuillResizeImage from 'quill-resize-image';
import 'quill-mention/autoregister';
import Quill from 'quill';
Quill.register('modules/resize', QuillResizeImage);

Chart.register(...registerables);

export function sharedAppConfig(
  routes: Routes,
  environmentParams: Environment = environment,
  extraProviders?: Array<Provider | EnvironmentProviders>,
): ApplicationConfig {
  const config = ErrorReporterService.sentryOptions(environmentParams, [
    environmentParams.jupiterAPIURL,
    environmentParams.processMapBaseURL,
  ]);
  // debugger;
  if (environmentParams.production) Sentry.init(config);

  if (!environmentParams?.debug) {
    // debugger;
    console.log('about to disable logging');
    window.console.log = function () {};
    // window.console.error = function () {};
    window.console.trace = function () {};
    window.console.warn = function () {};
    window.console.debug = function () {};
    console.log('did not disable logging');
  }

  return {
    providers: [
      provideRouter(
        routes.concat([
          {
            path: 'redirect',
            loadComponent: () =>
              import('./Reusables/reusable-pages/redirecter/redirecter.component').then(
                (c) => c.RedirecterComponent,
              ),
          },
        ]),
      ),
      importProvidersFrom(
        BrowserModule,
        EffectsModule.forRoot(...effects),
        FormsModule,
        IdlerModule,
        LayoutModule,
        MatDialogModule,
        MatProgressBarModule,
        MatSnackBarModule,
        NgIdleModule.forRoot(),
        NotificationsModule,
        QuillModule.forRoot(),
        ReactiveFormsModule,
        SharedModule,
        MatNativeDateModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        // TablePlainComponent,
        UtilityPipesModule,
        StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: !environmentParams.production,
          connectInZone: true,
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          // enabled: false,
          enabled: environmentParams.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000',
        }),
        CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory: adapterFactory,
        }),
      ),
      UtilityService,
      CurrencyPipe,
      DatePipe,
      TitleCasePipe,
      LoaderService,
      provideHttpClient(
        withInterceptorsFromDi(),
        withInterceptors(environmentParams.isJupiter ? [] : [coreToJupiterInterceptor]),
      ),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestLoggerInterceptorService,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthenticationInterceptorService,
        multi: true,
      },
      [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestTimeoutInterceptorService,
          multi: true,
        },
      ],
      // [{ provide: DEFAULT_TIMEOUT, useValue: 6 }],
      [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }],
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoggerInterceptorService,
        multi: true,
      },
      { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
      {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: <MatDialogConfig>{ autoFocus: false },
      },
      {
        provide: MAT_TABS_CONFIG,
        useValue: <MatTabsConfig>{ dynamicHeight: true, stretchTabs: true },
      },
      {
        provide: DATE_PIPE_DEFAULT_OPTIONS,
        useValue: <DatePipeConfig>{ dateFormat: 'EE, MMMM d, y, hh:mm:ss a' },
      },
      ...ErrorReporterService.sentryProviderOptions(),
      IsNavMenuEnabledPipe,
      provideAnimations(),
      ...(extraProviders || []),
    ],
  };
}
